import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { clinicianDashboardEndpoints } from "../apis";
import { setLoading } from "../../slices/profileSlice";
import { setDashboard } from "../../slices/dashboardSlice";
import moment from "moment-timezone";

const {
  GET_ALL_BOOKED_SLOTS_API,
  GET_DATE_ID,
  GET_ORGANIZATION_DOCTORS,
  GET_AVAILABLE_SLOTS_FOR_SPECIFIED_DATE,
  GET_SLOT_AVAILABILITY_STATUS,
  GET_AVAILABLE_SLOTS_FOR_DOCTOR_ON_SPECIFIED_DATE,
  BOOK_APPOINTMENT,
  GET_ORGANIZATION_LOGO,
} = clinicianDashboardEndpoints;

// GET ALL BOOKED SLOTS for BOOK APPOINTMENT
export function getAllBookedSlotsForBookAppointment(token, body) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result = [];
    try {
      const response = await apiConnector(
        "POST",
        GET_ALL_BOOKED_SLOTS_API,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      result = response?.data?.PatientList;

      console.log("GET_BOOKED_SLOT_API RESPONSE............", response);
      // dispatch(setDashboard(response?.data?.PatientList));
    } catch (error) {
      console.log("GET_BOOKED_SLOT_API ERROR............", error);
      // toast.error("Could Not Get Booked Slot Data");
    }
    dispatch(setLoading(false));
    return result;
    // toast.dismiss(toastId);
  };
}

// GET DATE ID
export function getDateID(requestBody) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector("POST", GET_DATE_ID, requestBody);
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      result = response?.data?.DateID;
    } catch (error) {
      console.log("DATE ID API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "ERROR WHILE FETCHING DATE ID"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// GET Available Slots For Specified Date
export function getAvailableSlotsForSpecifiedDate(token, requestBody) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector(
        "POST",
        GET_AVAILABLE_SLOTS_FOR_SPECIFIED_DATE,
        requestBody,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      result = response?.data?.AvailableTimes;
    } catch (error) {
      console.log("AVAILABLE SLOTS FOR SPECIFIED DATE API ERROR :", error);
      toast.error(
        error?.response?.data?.message ||
          "ERROR WHILE FETCHING AVAILABLE SLOTS FOR SPECIFIED DATE"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// *****************************

// GET ALL BOOKED SLOTS
export function getAllBookedSlots(token, body) {
  return async (dispatch, getState) => {
    const { showMeetingView } = getState().dashboard;
    if (showMeetingView) return; // Skip fetching if showMeetingView is true
    dispatch(setLoading(true));
    try {
      const timeZone = moment.tz.guess();
      const response = await apiConnector(
        "POST",
        GET_ALL_BOOKED_SLOTS_API + `?TimeZone=${timeZone}`,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log("GET_BOOKED_SLOT_API RESPONSE............", response);
      dispatch(setDashboard(response?.data?.PatientList));
    } catch (error) {
      console.log("GET_BOOKED_SLOT_API ERROR............", error);
      // toast.error("Could Not Get Booked Slot Data");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// GET Available Slots For Specified Date
export const getAvailableSlotsForDoctorOnSpecifiedDate = async (
  token,
  ID,
  Date
) => {
  const toastId = toast.loading("Loading...");
  let result = null;
  try {
    const timeZone = moment.tz.guess();
    const response = await apiConnector(
      "GET",
      GET_AVAILABLE_SLOTS_FOR_DOCTOR_ON_SPECIFIED_DATE +
        `?DID=${ID}&TimeZone=${timeZone}&OriginalDate=${Date}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    console.log(
      "GET_AVAILABLE_SLOTS_FOR_DOCTOR_ON_SPECIFIED_DATE API RESPONSE............",
      response
    );

    if (!response?.status === 200) {
      throw new Error(response?.data?.message);
    }
    result = response.data;
  } catch (error) {
    console.log(
      "GET_AVAILABLE_SLOTS_FOR_DOCTOR_ON_SPECIFIED_DATE API ERROR............",
      error
    );
    result = error.response.data;
    toast.error(error?.response?.data?.message || error?.response?.data?.msg);
  }
  toast.dismiss(toastId);
  return result;
};

// BOOK APPOINTMENT
export function bookAppointment(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", BOOK_APPOINTMENT, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Booked Successfully");
      dispatch(getAllBookedSlots(token));
      navigate(-1);
    } catch (error) {
      console.log("BOOK_APPOINTMENT API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Booking New Appointment"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET SLOT STATUS
export const getSlotAvailabilityStatus = async (token, ID, Date) => {
  // const toastId = toast.loading("Loading...");
  let result = null;
  try {
    const timeZone = moment.tz.guess();
    const response = await apiConnector(
      "GET",
      GET_SLOT_AVAILABILITY_STATUS +
        `?DID=${ID}&TimeZone=${timeZone}&OriginalDate=${Date}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    console.log(
      "GET_SLOT_AVAILABILITY_STATUS API RESPONSE............",
      response
    );

    if (!response?.status === 200) {
      throw new Error(response?.data?.message);
    }
    result = response.data;
  } catch (error) {
    console.log("GET_SLOT_AVAILABILITY_STATUS API ERROR............", error);
    result = error.response.data;
    toast.error(
      error?.response?.data?.message ||
        "ERROR WHILE FETCHING AVAILABLE SLOTS STATUS"
    );
  }
  // toast.dismiss(toastId);
  return result;
};
