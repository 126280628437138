import React from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import bannerBackground from "../assets/Images/OperationsManagement/PlatformManagement/bannerBackground.png";
import crown from "../assets/Images/OperationsManagement/PlatformManagement/crown.svg";

function PlatformManagement() {
  const navigate = useNavigate();
  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Platform Management
      </div>
      <Breadcrumb />

      {/* Banner */}
      <div className="w-full bg-[url('/src/assets/Images/OperationsManagement/PlatformManagement/bannerBackground.png')] mt-7 bg-cover bg-center text-white p-4 rounded-lg flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <img src={crown} alt="crown" className="w-8 h-8" />
          <div>
            <p className="font-bold text-lg">
              Unlock the power of best Clinician Dashboard with Premium
              Subscription
            </p>
            <p className="text-sm text-gray-300">
              Get all the essentials plus amazing premium-only features.
            </p>
          </div>
        </div>
        <button className="bg-white text-purple-700 px-4 py-2 rounded-full font-semibold flex items-center hover:bg-gray-100 transition-colors duration-200">
          Upgrade to Premium
          {/* <ArrowRight className="ml-2" size={16} /> */}
        </button>
      </div>

      <div className=" text-neutral-600 text-base font-medium font-poppins mt-24">
        Here are all the Company/Organization essentials you need to maintain
        regularly
      </div>
    </div>
  );
}

export default PlatformManagement;
