import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceCardRates: [],
  settings: {},
  telehealthPricing: [],
  customNotificationList: [],
  clinicCapacity: {},
  loading: false,
  allNurses: [],
  allDoctors: [],
  allBranches: [],
  allLabPartners: [],
  labTypes: [],
  allOrganizationUsers: [],
  allergyCategory: [],
  symptomsType: [],
};

const masterDataSlice = createSlice({
  name: "masterData",
  initialState: initialState,
  reducers: {
    setServiceCardRates(state, action) {
      state.serviceCardRates = action.payload;
    },
    setSettings(state, action) {
      state.settings = action.payload;
    },
    setTelehealthPricing(state, action) {
      state.telehealthPricing = action.payload;
    },
    setCustomNotificationList(state, action) {
      state.customNotificationList = action.payload;
    },
    setClinicCapacity(state, action) {
      state.clinicCapacity = action.payload;
    },
    setAllNurses(state, action) {
      state.allNurses = action.payload;
    },
    setAllDoctors(state, action) {
      state.allDoctors = action.payload;
    },
    setAllBranches(state, action) {
      state.allBranches = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setAllLabPartners(state, action) {
      state.allLabPartners = action.payload;
    },
    setAllOrganizationUsers(state, action) {
      state.allOrganizationUsers = action.payload;
    },
    setAllLabTypes(state, action) {
      state.labTypes = action.payload;
    },
    setAllergyCategory(state, action) {
      state.allergyCategory = action.payload;
    },
    setSymptomsType(state, action) {
      state.symptomsType = action.payload;
    },
  },
});

export const {
  setServiceCardRates,
  setSettings,
  setTelehealthPricing,
  setCustomNotificationList,
  setAllNurses,
  setAllDoctors,
  setAllBranches,
  setAllLabPartners,
  setAllLabTypes,
  setClinicCapacity,
  setAllOrganizationUsers,
  setAllergyCategory,
  setSymptomsType,
  setLoading,
} = masterDataSlice.actions;

export default masterDataSlice.reducer;
